import React, { useRef, useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { PlayCircle, PauseCircle } from 'react-feather';
import CaseSummary from './CaseSummary';
import Comments from './Comments';
import './OralArguments.css';

const OralArguments = () => {
  const { id } = useParams();
  const [caseData, setCaseData] = useState(null);
  const [transcriptData, setTranscriptData] = useState([]);
  const [displayedWords, setDisplayedWords] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);
  const transcriptRef = useRef(null);

  useEffect(() => {
    fetchCaseData();
  }, [id]);

  const fetchCaseData = async () => {
    try {
      const response = await axios.get(`https://aijur.is/api/cases/${id}`);
      setCaseData(response.data);
      
      if (response.data.file_name) {
        const transcriptResponse = await axios.get(`https://audio.aijur.is/json/${response.data.file_name}.json`);
        const flattenedWords = transcriptResponse.data.flatMap(segment => segment.words);
        setTranscriptData(flattenedWords);
      }
    } catch (error) {
      console.error('Error fetching case data:', error);
    }
  };

  useEffect(() => {
    if (isPlaying && audioRef.current) {
      const updateTranscript = () => {
        const currentTime = audioRef.current.currentTime;
        const newDisplayedWords = transcriptData.filter(word => word.start <= currentTime);
        setDisplayedWords(newDisplayedWords);
      };

      const intervalId = setInterval(updateTranscript, 50); // Update every 50ms for smoother appearance

      return () => clearInterval(intervalId);
    }
  }, [isPlaying, transcriptData]);

  useEffect(() => {
    if (transcriptRef.current) {
      transcriptRef.current.scrollTop = transcriptRef.current.scrollHeight;
    }
  }, [displayedWords]);

  const togglePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const renderGroupedTranscript = () => {
    let currentSpeaker = null;
    let currentParagraph = [];
    const paragraphs = [];

    displayedWords.forEach((word, index) => {
      if (word.speaker !== currentSpeaker) {
        if (currentParagraph.length > 0) {
          paragraphs.push(
            <p key={index - currentParagraph.length}>
              <strong>{currentSpeaker}: </strong>
              {currentParagraph}
            </p>
          );
        }
        currentSpeaker = word.speaker;
        currentParagraph = [];
      }
      currentParagraph.push(
        <React.Fragment key={index}>
          <span className="transcript-word">{word.word}</span>
          {' '}
        </React.Fragment>
      );
    });

    if (currentParagraph.length > 0) {
      paragraphs.push(
        <p key={displayedWords.length}>
          <strong>{currentSpeaker}: </strong>
          {currentParagraph}
        </p>
      );
    }

    return paragraphs;
  };

  if (!caseData || transcriptData.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <Container fluid className="oral-arguments-container py-4">
      <Row>
        <Col lg={3}>
          <CaseSummary caseData={caseData} />
          <div className="mt-4">
            <Comments caseId={id} />
          </div>
        </Col>
        <Col lg={9}>
          <Card className="mb-4 border-0 shadow-sm">
            <Card.Header className="bg-f1efe9 d-flex justify-content-between align-items-center py-2">
              <h6 className="mb-0">Oral Arguments</h6>
              <div className="d-flex align-items-center">
                <span className="me-3">{audioRef.current && formatTime(audioRef.current.currentTime)}</span>
                <Button variant={isPlaying ? "outline-secondary" : "outline-primary"} size="sm" onClick={togglePlayPause} className="btn-xs">
                  {isPlaying ? <PauseCircle size={16} /> : <PlayCircle size={16} />}
                </Button>
              </div>
            </Card.Header>
            <Card.Body>
              <div className="transcript-section mb-4" ref={transcriptRef} style={{height: '400px', overflowY: 'auto'}}>
                {renderGroupedTranscript()}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <audio 
        ref={audioRef} 
        src={`https://audio.aijur.is/mp3/${caseData.file_name}.mp3`}
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
      />
    </Container>
  );
};

export default OralArguments;