import React, { useState, useEffect } from 'react';
import { Card, Form, Button, ListGroup } from 'react-bootstrap';
import axios from 'axios';

const Comments = ({ caseId }) => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  
  // Assume we have a way to get the current user's ID
  const currentUserId = 1; // This should be replaced with actual user authentication

  useEffect(() => {
    fetchComments();
  }, [caseId]);

  const fetchComments = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/cases/${caseId}/comments`);
      setComments(response.data);
    } catch (error) {
      console.error('Error fetching comments:', error);
    }
  };

  const handleCommentChange = (e) => {
    setNewComment(e.target.value);
  };

  const handleSubmitComment = async (e) => {
    e.preventDefault();
    if (!newComment.trim()) return;

    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/cases/${caseId}/comments`, {
        content: newComment,
        user_id: currentUserId
      });
      setNewComment('');
      fetchComments();
    } catch (error) {
      console.error('Error submitting comment:', error);
    }
  };

  return (
<Card className="mb-4">
  <Card.Header as="h6" className="bg-f1efe9">Comments</Card.Header>
  <Card.Body>
        <Form onSubmit={handleSubmitComment}>
          <Form.Group controlId="newComment">
            <Form.Control 
              as="textarea" 
              rows={3} 
              value={newComment} 
              onChange={handleCommentChange} 
              placeholder="Add a new comment..."
            />
          </Form.Group>
          <Button variant="light" type="submit" className="mb-4 sm">Add Comment</Button>
        </Form>
        <ListGroup variant="flush" className="mt-4">
      {comments.map((comment, index) => (
        <ListGroup.Item key={index} className="bg-transparent pb-3">
          <p className="mb-1 font-styrene font-bold">{comment.username} - {new Date(comment.timestamp).toLocaleString()}</p>
          <p className="mb-0 text-lg">{comment.content}</p>
        </ListGroup.Item>
      ))}
    </ListGroup>
  </Card.Body>
</Card>
  );
};

export default Comments;