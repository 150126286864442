import React from 'react';
import { Card } from 'react-bootstrap';

const CaseSummary = ({ caseData }) => {
  return (
<Card className="mb-4">
  <Card.Header as="h6" className="bg-f1efe9">Case Summary</Card.Header>
  <Card.Body>
    <Card.Title className="font-styrene font-bold mb-3">{caseData.case_name}</Card.Title>
    <Card.Text className="text-lg mb-2">{caseData.court_name}</Card.Text>
    <Card.Text>{caseData.case_summary}</Card.Text>
  </Card.Body>
</Card>
  );
};

export default CaseSummary;