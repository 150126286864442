import React from 'react';
import { BrowserRouter, Routes, Route, NavLink } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import HomePage from './HomePage';
import OralArguments from './OralArguments';
import { CardList } from 'react-bootstrap-icons';

import Footer from './footer_files/Footer';
import PrivacyPolicyPage from './footer_files/PrivacyPolicyPage';
import CookiePolicyPage from './footer_files/CookiePolicyPage';
import TermsAndConditionsPage from './footer_files/TermsAndConditionsPage';
import DisclaimerPage from './footer_files/DisclaimerPage';
import AcceptableUsePolicyPage from './footer_files/AcceptableUsePolicyPage';

function App() {
  return (
    <BrowserRouter>
      <div className="flex flex-col min-h-screen">
        <Navbar bg="light" variant="light" className="shadow-sm">
          <Container>
            <Navbar.Brand as={NavLink} to="/" className="brand-logo">AIjuris</Navbar.Brand>
            <Nav className="ms-auto">
              <NavLink to="/" className="nav-link">
                <CardList size={24} />
              </NavLink>
            </Nav>
          </Container>
        </Navbar>
        <main className="flex-grow">
          <Container className="py-4">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/oral-arguments/:id" element={<OralArguments />} />
              <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
              <Route path="/cookie-policy" element={<CookiePolicyPage />} />
              <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
              <Route path="/disclaimer" element={<DisclaimerPage />} />
              <Route path="/acceptable-use-policy" element={<AcceptableUsePolicyPage />} />
            </Routes>
          </Container>
        </main>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;