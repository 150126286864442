import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Edit, Plus, Mic } from 'lucide-react';
import InfiniteScroll from 'react-infinite-scroll-component';

const HomePage = () => {
  const [cases, setCases] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const navigate = useNavigate();

  const fetchCases = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/cases?page=${page}&limit=20`);
      const newCases = response.data.cases;
      setCases(prevCases => [...prevCases, ...newCases]);
      setHasMore(newCases.length === 20);
      setPage(prevPage => prevPage + 1);
    } catch (error) {
      console.error('Error fetching cases:', error);
    }
  }, [page]);

  useEffect(() => {
    fetchCases();
  }, []);

  const handleOralArguments = (id) => {
    navigate(`/oral-arguments/${id}`);
  };

  const handleEdit = (id, caseType) => {
    if (caseType === 'practice') {
      console.log(`Edit case ${id}`);
    }
  };

  return (
    <div className="p-6 bg-gray-50 min-h-screen font-tiempos">
      <h1 className="text-3xl font-styrene mb-6 text-gray-800">Case Listings</h1>
      <button className="mb-6 bg-grey-500 text-white px-4 py-2 rounded-sm flex items-center hover:bg-blue-600">
        <Plus className="mr-2" size={20} />
        Add New Case
      </button>
      <InfiniteScroll
        dataLength={cases.length}
        next={fetchCases}
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
        endMessage={<p>No more cases to load.</p>}
      >
        <div className="bg-white rounded-lg shadow-md overflow-hidden">
          {cases.map((caseItem, index) => (
            <div key={caseItem.id} className={`p-4 ${index !== cases.length - 1 ? 'border-b' : ''} flex justify-between items-center`}>
              <div>
                <h2 className="font-tiempos text-base text-gray-800">{caseItem.case_name}</h2>
                {caseItem.case_summary && (
                  <p className="text-gray-700 text-sm mt-1">{caseItem.case_summary}</p>
                )}
              </div>
              <div className="flex space-x-2">
                <button 
                  onClick={() => handleOralArguments(caseItem.id)}
                  className="text-gray-600 px-3 py-1 rounded-md flex items-center hover:bg-gray-100"
                >
                  <Mic className="mr-1" size={16} />
                  Oral Arguments
                </button>
                <button 
                  onClick={() => handleEdit(caseItem.id, caseItem.case_type)}
                  className={`text-gray-600 px-3 py-1 rounded-md flex items-center ${caseItem.case_type === 'real' ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-100'}`}
                  disabled={caseItem.case_type === 'real'}
                >
                  <Edit className="mr-1" size={16} />
                  Edit
                </button>
              </div>
            </div>
          ))}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default HomePage;